import React from 'react';
import App from './components/app/App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './utils/serviceWorker';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import jwt_decode from 'jwt-decode';
import { Provider } from 'react-redux';
import reducer from 'reducers';
import './_content/scss/main.scss';
import { init } from 'lib/utils';
import { REACT_APP_API_URL } from 'config';
import { createRoot } from 'react-dom/client';
import api, { setBaseUrl } from 'lib/services/api';
import { setupAxiosInterceptors } from 'lib/utils/api';
import { updateUrlAndVersion } from 'lib/actions';
import { initSentry, sentryErrorCb } from './utils/sentry'

const accessToken = localStorage.getItem('accessToken');
const cognitoToken = localStorage.getItem('cognitoToken');
const token = accessToken || cognitoToken;

let middleware = [thunk, api.middleware];
const store = configureStore({
    reducer,
    middleware: () => middleware,
});

let apiUrl = REACT_APP_API_URL;

if (token) {
    const userData = jwt_decode(token);

    if (userData.accountIdV2) {
        apiUrl = apiUrl.replace(/(\/v\d+)$/, '/v2');
        store.dispatch(updateUrlAndVersion('/v2'));
    }
}

init(apiUrl, token, {}, sentryErrorCb);
setBaseUrl(apiUrl);
setupAxiosInterceptors(store);

initSentry();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
);

serviceWorker.unregister();
