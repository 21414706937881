import React from 'react';
import FeesModal from '../feesModal/FeesModal';

const StandingOrderFeeModal = ({
    fee,
    recurranceFee,
    handleSubmit,
    closeModal,
    isInternalPayee
}) => {
    return (
        <FeesModal
            action="Setting up a standing order"
            fee={fee}
            standingOrderRecurranceFee={recurranceFee}
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            isInternalPayee={isInternalPayee}
        />
    );
}

export default StandingOrderFeeModal;
